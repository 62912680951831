<template>
  <div>
    <!-- Machines -->
    <div class="text-activity-notes text-left" v-if="this.currentEntry.machine_list == undefined">
      {{ $t("activities.multiple.details.machines") }}
    </div>
    <div v-else v-show="(!this.currentEntry.machine_list || !this.currentEntry.machine_list.length) && !subcontracting" class="text-activity-notes enpty-attr text-left">
      {{ $t("components.Machines.no-machine")}}
    </div>
    <b-row class="m-0 bg-white" v-for="machine in this.currentEntry.machine_list" v-bind:key="machine.model" :value="machine.model">
      <b-col cols="12" class="text-infos">{{ machine.machineType_designation }} {{ machine.model }}</b-col>
    </b-row>
    <!-- Sous-traitant -->
    <div v-if="subcontracting" class="m-0 bg-white">
      <b-row class="m-0 pl-2 bg-white subtitle-form change-subtitle-color">{{ $t("components.EditMachines.subcontractedActiviy")}}</b-row>
      <b-row class="m-0 bg-white text-infos">
        <b-col cols="7" class="subtitle-form change-subtitle-color">{{ $t("components.EditMachines.eta")}}</b-col>
        <b-col cols="5" class="subtitle-form change-subtitle-color">{{ $t("components.EditMachines.price")}}</b-col>
      </b-row>
      <b-row class="m-0 bg-white">
        <b-col cols="7" class="text-infos">{{ this.currentEntry.subcontractor }} </b-col>
        <b-col cols="5" class="pl-0 text-infos">{{ this.currentEntry.subcontractorCost || "-" }} € </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import tractor from "@/assets/images/tractor.svg";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      icon: tractor,
    };
  },
  components: {},
  computed: {
    subcontracting() {
      return this.currentEntry.subcontractor || this.currentEntry.subcontractorCost;
    }
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 1em;
  float: left;
  margin-right: 5px;
}

/**
 * Text params for empty attribut.
 */
.enpty-attr {
  color: $danger-color;
}

/**
 * Change notes params.
 */
.machines-params {
  border-radius: 6px;
  padding: 0px 8px 0px 0px;
}

/**
 * Change the subtitle color.
 */
.change-subtitle-color {
  color: $primary-color;
}
</style>
